import { Controller } from "@hotwired/stimulus"
import { isElementXPercentInViewport, getPosition } from "../helpers/view_offset"

/*
 * Usage
 * =====
 *
 * add data-controller="user-training" to main element
 * 
 * Actions:
 * data-action="click->user-training#next"
 *
 * Targets:
 * data-user-training-target="step" data-step="1" data-target="1"
 * 
 * TargetDivs:
 * data-training-target="1"
 *
 */
export default class extends Controller {
  static targets = ["overlay"]

  connect() {
    this.offsetX = 0
    this.offsetY = 40

    this.step = this.step || 0
  }

  start() {
    this.step = 1
    this.overlayTarget.classList.remove("hidden")
    this.renderNew()
  }

  next() {
    this.previousStepElement = document.querySelector(`[data-training-step="${this.step}"]`)
    this.previousTargetElement = document.querySelector(`[data-training-target="${this.currentStepElement.dataset.target}"]`)
    this.step++
    this.removePrevious()
    this.renderNew()
  }

  previous() {
    this.previousStepElement = document.querySelector(`[data-training-step="${this.step}"]`)
    this.previousTargetElement = document.querySelector(`[data-training-target="${this.currentStepElement.dataset.target}"]`)
    this.step--
    this.removePrevious()
    this.renderNew()
  }

  removePrevious() {
    this.previousTargetElement.classList.remove("training-outline")
    this.previousTargetElement.classList.remove("pointer-events-none")
    this.previousStepElement.classList.remove("absolute")
    this.previousStepElement.classList.add("hidden")
  }

  renderNew() {
    this.currentStepElement = document.querySelector(`[data-training-step="${this.step}"]`)
    this.currentTargetElement = document.querySelector(`[data-training-target="${this.currentStepElement.dataset.target}"]`)

    this.currentStepElement.classList.remove("hidden")
    this.currentStepElement.classList.add("absolute")
    this.currentTargetElement.classList.add("training-outline")
    this.currentTargetElement.classList.add("pointer-events-none")

    let position = getPosition(this.currentTargetElement)

    if (position.x > window.innerWidth) {
      this.currentStepElement.style.left = (this.offsetX + window.scrollX) + "px"
    } else {
      if (window.innerWidth < (position.x + this.offsetX + this.currentStepElement.offsetWidth + window.scrollX)) {
        this.currentStepElement.style.left = (position.x + this.offsetX - this.currentStepElement.offsetWidth + window.scrollX) + "px"
      } else {
        this.currentStepElement.style.left = (position.x + this.offsetX + window.scrollX) + "px"
      }
    }

    this.currentStepElement.style.top = (position.y + this.offsetY + window.scrollY) + "px"

    if (window.innerWidth < 960) {
      this.currentTargetElement.scrollIntoView({ behavior: 'smooth' })
    }
  }
}

import { Controller } from '@hotwired/stimulus'

/*
 * Usage
 * =====
 *
 * add data-controller="localstorage" to common ancestor
 *
 * Action:
 * data-action="click->localstorage#save"
 *
 * Targets:
 * data-localstorage-target="object"
 *
 */
export default class extends Controller {
  static targets = ["object"];

  connect() {
    this.localStorageKey = window.location;
  }

  getData() {
    const data = {};

    this.objectTargets.forEach((target) => {
      data[target.name] = target.value;
    });

    return data;
  }

  saveToLocalStorage() {
    const data = this.getData();
    // Save the form data into localStorage. We need to convert the data Object into a String.
    localStorage.setItem(this.localStorageKey, JSON.stringify(data));
  }
}
// Entry point for the build script in your package.json
// Some js files not loading on first page load, so we need to disable turbo drive
// @TODO: fix this turbo drive issue
// Turbo.session.drive = false

import "@hotwired/turbo-rails"
import "./controllers"
import "trix"
import "@rails/actiontext"
import "preline"

window.addEventListener("trix-file-accept", function (event) {
  event.preventDefault();
  alert("File attachments are not supported!");
});

// Custom redirect action
Turbo.StreamActions.redirect = function () {
  Turbo.visit(this.target);
};

// Custom confirm dialog
// Turbo.setConfirmMethod((message, element) => {
//   let dialog = document.getElementById("hs-badsic-modal")
//   dialog.querySelector("p").textContent = message
//   HSOverlay.open(dialog)

//   return new Promise((resolve, reject) => {
//     dialog.addEventListener("close", () => {
//       resolve(dialog.returnValue == "confirm")
//     }, { once: true })
//   })
// });

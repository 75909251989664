import { Controller } from "@hotwired/stimulus"

/*
 * Usage
 * =====
 *
 * add data-controller="table-expand" to main container
 *
 * Action:
 * data-action="click->table-expand#toggle"
 *
 * Targets:
 * data-table-expand-target="expandable"
 * 
 */
export default class extends Controller {
  static targets = ["expandable", "button"];

  toggle(e) {
    this.expandableTarget.classList.toggle("max-w-7xl");
  }
}

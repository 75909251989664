import { Controller } from "@hotwired/stimulus"

/*
 * Usage
 * =====
 *
 * add data-controller="date-input" to common ancestor
 *
 * Action:
 * data-action="click->date-input#fill"
 *
 * Targets:
 * data-date-input-target="input"
 * data-date-input-target="button" data-date-fill="date-to-fill"
 *
 */
export default class extends Controller {
  static targets = ["input", "button"];

  connect() {}

  fill(e) {
    e.preventDefault();

    this.dateValue = e.target.dataset.dateFill;

    this.inputTargets.forEach((target) => {
      target.value = this.dateValue;
    });
  }
}

import { Controller } from '@hotwired/stimulus'

/*
 * Usage
 * =====
 *
 * add data-controller="flash" to element
 *
 */
export default class extends Controller {
  connect() {
    this.timer = 2000
    this.flash = this.element

    setTimeout(() => {
      this.flash.remove()
    }, this.timer)
  }
}
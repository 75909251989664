import { Controller } from "@hotwired/stimulus"

/*
 * Usage
 * =====
 *
 * add data-controller="checkbox" to main container
 *
 * Action:
 * data-action="click->checkbox#all"
 * data-action="click->checkbox#none"
 *
 * Targets:
 * data-checkbox-target="checkbox" individual checkbox
 * 
 */
export default class extends Controller {
  static targets = ["checkbox"];

  all(e) {
    this.element.querySelectorAll("input[type=checkbox]").forEach((checkbox) => {
      checkbox.checked = true;
    });
  }

  none(e) {
    this.element.querySelectorAll("input[type=checkbox]").forEach((checkbox) => {
      checkbox.checked = false;
    });
  }
}

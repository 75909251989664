import { Controller } from "@hotwired/stimulus"
import { Calendar } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';

/*
 * Usage
 * =====
 *
 * add data-controller="calendar" to calendar element
 *
 */
export default class extends Controller {
  connect() {
    this.calendar = new Calendar(this.element, {
      plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin],
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
      },
      navLinks: true, // can click day/week names to navigate views
      editable: true,
      dayMaxEvents: true, // allow "more" link when too many events
      events: {
        url: '/calendar.json',
      },
    });

    this.calendar.render();
  }
}

import { Controller } from "@hotwired/stimulus";

/*
 * Usage
 * =====
 *
 * add data-controller="preview" to common ancestor
 *
 * Action:
 * data-action="change->preview#render"
 *
 * Targets:
 * data-preview-target="input"
 * data-preview-target="preview"
 *
 */
export default class extends Controller {
  static targets = ["input", "preview"];

  connect() {
    this.preview = this.previewTarget;
  }

  render() {
    let input = this.inputTarget;
    let preview = this.previewTarget;
    let file = input.files[0];
    let reader = new FileReader();

    reader.onloadend = function () {
      preview.src = reader.result;
    };

    if (file) {
      reader.readAsDataURL(file);
    } else {
      preview.src = "";
    }
  }
}
import { Controller } from "@hotwired/stimulus"
import SlimSelect from "slim-select"

/*
 * Usage
 * =====
 *
 * add data-controller="select" to input
 *
 */
export default class extends Controller {
  connect() {
    new SlimSelect({
      select: this.element,
      showSearch: true,
      settings: {
        allowDeselect: true,
        placeholderText: 'Select a country',
      }
    });
  }
}
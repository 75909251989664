import { Controller } from "@hotwired/stimulus"

/*
 * Usage
 * =====
 *
 * add data-controller="card-click" to main container
 *
 * Action:
 * data-action="click->card-click#send"
 * 
 */
export default class extends Controller {
  send(e) {
    this.href = e.currentTarget.dataset.href

    if (this.href) { window.location = this.href }
  }
}

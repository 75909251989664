import { Controller } from "@hotwired/stimulus"

/*
 * Usage
 * =====
 *
 * add data-controller="table-click" to main container
 *
 * Action:
 * data-action="click->table-click#send"
 * 
 */
export default class extends Controller {
  send(e) {
    this.href = e.currentTarget.dataset.href

    if (e.target.closest('td').dataset.index == e.currentTarget.dataset.ignoreTarget) { return }

    if (this.href) {
      this.link = e.currentTarget.querySelector("#"+this.href)

      if (this.link) {
        this.link.click()
      }
    }
  }
}

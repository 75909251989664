import { Controller } from "@hotwired/stimulus"

/*
 * Usage
 * =====
 *
 * add data-controller="table-toggle" to common ancestor, data-local-key="unique-key"
 *
 * Action:
 * data-action="click->table-toggle#toggle" data-index="0" data-table-toggle-target="pointer"
 *
 * Targets:
 * data-table-toggle-target="toggleable" data-css-class="class-to-toggle" data-index="0"
 * data-table-toggle-target="counter" data-countables="attribute-to-count" ex. data-countables="th" or "th, td"
 * data-table-toggle-target="status"
 *
 */
export default class extends Controller {
  static targets = ["toggleable", "counter", "pointer", "status"];

  connect() {
    this.localStorageKey = this.element.dataset.localKey;
    this.timeout = null;

    this.loadFromLocalStorage();
    this.updateCounter();
  }

  toggle(e) {
    this.elementIndex = e.target.dataset.index;

    this.toggleableTargets.forEach((target) => {
      if (target.dataset.index == this.elementIndex) {
        target.classList.toggle(target.dataset.cssClass);
      }
    });

    this.saveToLocalStorage(e);
    this.updateCounter();
    this.printLoading();
  }

  updateCounter() {
    this.countables = this.counterTargets[0].dataset.countables;

    this.counterTargets.forEach((target) => {
      target.innerText = this.toggleableTargets.filter((target) => {
        return target.classList.contains(target.dataset.cssClass) && target.matches(this.countables);
      }, this).length;
    });
  }

  saveToLocalStorage(e) {
    const data = {};

    this.toggleableTargets.forEach((target) => {
      data[target.dataset.index] = !target.classList.contains(target.dataset.cssClass);
    });

    // Save the form data into localStorage. We need to convert the data Object into a String.
    localStorage.setItem(this.localStorageKey, JSON.stringify(data));
  }

  loadFromLocalStorage() {
    if (!this.localStorageKey) return;

    // Get the form data from localStorage. localStorage returns a String so we need to convert it back into an Object.
    const data = JSON.parse(localStorage.getItem(this.localStorageKey));

    if (data) {
      // Loop through each of the target and check if there is a corresponding value in localStorage.
      this.toggleableTargets.forEach((target) => {
        if (!data[target.dataset.index]) {
          target.classList.toggle(target.dataset.cssClass);
        }
      });

      // Loop through each of the pointer and check if there is a corresponding value in localStorage.
      // This is for checkboxes that are needed to be checked.
      this.pointerTargets.forEach((pointer) => {
        pointer.checked = data[pointer.dataset.index];
      });
    }
  }

  printLoading() {
    clearTimeout(this.timeout);

    this.statusTarget.classList.remove("hidden");

    this.timeout = setTimeout(() => {
      this.statusTarget.classList.add("hidden");
    }, 100);
  }
}

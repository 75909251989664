import { Controller } from "@hotwired/stimulus"

/*
 * Usage
 * =====
 *
 * add data-controller="filters" to container
 *
 */
export default class extends Controller {
  static targets = ["form"];

  connect() {
    this.timeout = null
  }

  submit(event) {
    clearTimeout(this.timeout)
    this.append_loading()

    let form = this.formTarget
    let timeOut = event.target.dataset.timeOut || 1000

    this.timeout = setTimeout(function () {
      form.requestSubmit()
    }, timeOut)
  }

  append_loading() {
    this.loadingTarget = document.querySelectorAll("[data-filter-target='loading']")
    this.loadingTarget.forEach(element => {
      let loading = document.createElement("span")
      loading.classList.add("loading-spinner")
      element.innerHTML = ""
      element.appendChild(loading)
    })

    this.loadingTableTarget = document.querySelectorAll("[data-filter-target='loading-table']")
    this.loadingTableTarget.forEach(element => {
      element.classList.add("loading-table-spinner")
    })
  }
}